import types from '@havppen/gql/types'
import { mapMemberPublicFromGql } from '@havppen/types/src/member'
import {
  ProductDefaultProps,
  ProductOrderType,
  ProductStatus,
  ProductStyleMetadataBriefProps,
} from '@havppen/types/src/product'
import { MemberPublicBriefProps } from 'src/types/member'
import { mapProductDiscountFromGql } from 'src/types/product'
import {
  productMemberTagRestrictionFilterTypes,
  productMemberTagRestrictionTypes,
  productVisibilityTypes,
} from 'src/types/productVisibility'
import { getDiscount } from 'src/utils/discount'
import { NIL as NIL_UUID } from 'uuid'

export type AppointmentBriefProps = ProductDefaultProps &
  ProductStyleMetadataBriefProps & {
    title: string
    salePrice: number | null
    status: ProductStatus

    isPrivate: boolean
    isLive: boolean

    featuredImageUrl: string | null
    coverVideoUrl: string | null
    authorId: string | null
    author: MemberPublicBriefProps | null

    defaultLocation: string | null
    duration: number
    participantLowerLimit: number
    defaultParticipantLimit: number | null

    createdAt: Date
    updatedAt: Date
  }

export type AppointmentsQueryOptions = {
  limit?: number
  offset?: number
  status?: ProductStatus
  orderBy?: ProductOrderType
  search?: string | null
  authorId?: string
  categorySlug?: string | null
  categoryId?: string | null
  showPrivate?: boolean
  purchaseMemberId?: string
  isAuthenticated?: boolean
  currentMemberId?: string
}
export const mapAppointmentsQueryOptionsToGqlVariables = (
  options: AppointmentsQueryOptions,
  params: { appId: string },
) => {
  const orderBy: types.appointment_order_by[] | undefined =
    options?.orderBy === 'hot'
      ? [
          { appointment_possessions_aggregate: { count: 'desc' as types.order_by } },
          { online_at: 'desc' as types.order_by },
        ]
      : options?.orderBy === 'recommend'
      ? [{ weight: 'desc' as types.order_by }, { online_at: 'desc' as types.order_by }]
      : options?.orderBy === 'latest'
      ? [{ online_at: 'desc' as types.order_by }]
      : [{ position: 'asc' as types.order_by }]

  const appointmentVisibilityQueries: types.InputMaybe<types.appointment_bool_exp[]> = [
    {
      _not: {
        appointment_visibility: {},
      },
    },
    {
      _not: {
        appointment_visibility: {
          visibility_type: { _in: [productVisibilityTypes.authOnly, productVisibilityTypes.whitelist] },
        },
      },
    },
  ]
  if (options?.isAuthenticated) {
    appointmentVisibilityQueries.push({
      appointment_visibility: {
        visibility_type: { _eq: productVisibilityTypes.authOnly },
      },
    })
  }
  if (options?.currentMemberId && options?.currentMemberId !== NIL_UUID) {
    appointmentVisibilityQueries.push({
      appointment_visibility: {
        visibility_type: { _eq: productVisibilityTypes.whitelist },
        product_member_tag_restrictions: {
          type: { _eq: productMemberTagRestrictionTypes.visibility },
          filter_type: { _eq: productMemberTagRestrictionFilterTypes.include },
          member_tag_members: { member_id: { _eq: options.currentMemberId } },
        },
      },
    })
  }

  const variables: types.APP_GET_APPOINTMENTSVariables = {
    limit: options?.limit,
    offset: options?.offset,
    orderBy,
    condition: {
      app_id: { _eq: params.appId },
      status: options?.status ? { _eq: options.status } : undefined,
      is_private: options.showPrivate ? undefined : { _eq: false },
      author_id: options?.authorId ? { _eq: options.authorId } : undefined,
      appointment_categories: options?.categorySlug
        ? { category: { slug: { _eq: options.categorySlug } } }
        : options?.categoryId
        ? { category: { id: { _eq: options.categoryId } } }
        : undefined,
      appointment_possessions: options.purchaseMemberId ? { member_id: { _eq: options.purchaseMemberId } } : undefined,
      deleted_at: { _is_null: true },
      online_at: { _lte: 'now()' },
      _and: [
        { _or: [{ offline_at: { _is_null: true } }, { offline_at: { _gte: 'now()' } }] },
        ...(options?.search
          ? [
              {
                _or: [
                  { title: { _ilike: `%${options.search}%` } },
                  { author: { name: { _ilike: `%${options.search}%` } } },
                  { author: { display_name: { _ilike: `%${options.search}%` } } },
                ],
              },
            ]
          : []),
      ],
      _or: appointmentVisibilityQueries,
    },
  }
  return variables
}

export const formatGqlAppointments = (data: types.APP_GET_APPOINTMENTS | undefined) => {
  return (data?.appointment || []).map(appointment => {
    const participantLowerLimit = appointment.participant_lower_limit ?? 0
    const defaultParticipantLimit = appointment.default_participant_limit ?? null
    const listPrice = appointment.list_price
    const status = appointment.status as ProductStatus

    const productDiscounts = mapProductDiscountFromGql(appointment.appointment_discounts)
    const { discountPrice: salePrice } = getDiscount({
      productDiscounts,
      listPrice,
      status,
    })

    return {
      id: appointment.id,
      slug: appointment.slug,
      title: appointment.title,
      categories: appointment.appointment_categories.map(appointmentCategory => ({
        id: appointmentCategory.category.id,
        slug: appointmentCategory.category.slug,
        name: appointmentCategory.category.name,
      })),
      path: `/appointment/${appointment.slug ?? appointment.id}`,

      isPrivate: appointment.is_private ?? false,
      isLive: appointment.is_live ?? false,

      isPriceHidden: appointment.is_price_hidden ?? false,
      isAuthorInfoHidden: appointment.is_author_info_hidden ?? false,
      isParticipantCountHidden: appointment.is_participant_count_hidden ?? false,
      isDiscountCountdownVisible: appointment.is_discount_countdown_visible ?? false,

      listPrice,
      salePrice,
      coverVideoUrl: appointment.cover_video_url,
      featuredImageUrl: appointment.featured_image_url,
      authorId: appointment.author_id,
      author: appointment.author ? mapMemberPublicFromGql(appointment.author) : null,

      defaultLocation: appointment.default_location,
      duration: appointment.duration,
      participantLowerLimit,
      defaultParticipantLimit,
      purchaseCount: appointment.appointment_possessions_aggregate.aggregate?.count ?? 0,

      reviewCount: appointment.product_review_summary?.review_count ?? null,
      averageRating: appointment.product_review_summary?.average_rating ?? null,

      status: appointment.status as ProductStatus,
      createdAt: new Date(appointment.created_at),
      updatedAt: new Date(appointment.updated_at),
    }
  }) as AppointmentBriefProps[]
}
