import { gql } from '@apollo/client'
import { MEMBER_PUBLIC_COLUMNS } from './member'

export const APP_GET_APPOINTMENTS = gql`
  query APP_GET_APPOINTMENTS(
    $condition: appointment_bool_exp
    $limit: Int
    $offset: Int
    $orderBy: [appointment_order_by!]
  ) {
    appointment_aggregate(where: $condition) {
      aggregate {
        count
      }
    }

    appointment(where: $condition, limit: $limit, offset: $offset, order_by: $orderBy) {
      id
      title
      author_id
      list_price
      slug
      featured_image_url
      cover_video_url
      status
      is_private
      is_live

      type: metadata(path: "type")
      is_price_hidden: metadata(path: "isPriceHidden")
      is_author_info_hidden: metadata(path: "isAuthorInfoHidden")
      is_participant_count_hidden: metadata(path: "isParticipantCountHidden")
      is_discount_countdown_visible: metadata(path: "isDiscountCountdownVisible")

      duration
      default_location
      participant_lower_limit
      default_participant_limit

      created_at
      updated_at

      author {
        ...MEMBER_PUBLIC_COLUMNS
      }

      appointment_categories {
        id
        category {
          id
          name
          slug
        }
      }

      appointment_discounts(where: { start_at: { _lte: "now()" } }) {
        id
        name
        type
        target_unit
        target_amount
        start_at
        end_at
        base_limit_unit
        base_limit_amount
        product_id
        created_at
      }

      appointment_possessions_aggregate {
        aggregate {
          count
        }
      }

      product_review_summary {
        review_count
        average_rating
      }

      appointment_visibility {
        visibility_type
        purchasable_type
      }
    }
  }
  ${MEMBER_PUBLIC_COLUMNS}
`

export const APP_GET_APPOINTMENT = gql`
  query APP_GET_APPOINTMENT($condition: appointment_bool_exp) {
    appointment(where: $condition, limit: 1) {
      id
      title
      author_id
      list_price
      slug
      featured_image_url
      cover_video_url
      status
      is_private
      is_live

      type: metadata(path: "type")
      is_price_hidden: metadata(path: "isPriceHidden")

      abstract
      content
      metadata
      seo_attributes

      duration
      default_location
      participant_lower_limit
      default_participant_limit

      created_at
      updated_at

      author {
        ...MEMBER_PUBLIC_COLUMNS
      }

      appointment_categories {
        id
        category {
          id
          name
          slug
        }
      }

      appointment_possessions_aggregate {
        aggregate {
          count
        }
      }

      product_review_summary {
        review_count
        average_rating
      }

      appointment_discounts(where: { start_at: { _lte: "now()" } }) {
        id
        name
        type
        target_unit
        target_amount
        start_at
        end_at
        base_limit_unit
        base_limit_amount
        product_id
        created_at
      }

      appointment_visibility {
        visibility_type
        purchasable_type
      }
    }
  }
  ${MEMBER_PUBLIC_COLUMNS}
`

export const INSERT_APPOINTMENT_SESSION_MEMBER = gql`
  mutation INSERT_APPOINTMENT_SESSION_MEMBER($object: appointment_session_member_insert_input!) {
    insert_appointment_session_member_one(object: $object) {
      id
      no
    }
  }
`

export const UPDATE_APPOINTMENT_SESSION_MEMBER = gql`
  mutation UPDATE_APPOINTMENT_SESSION_MEMBER(
    $condition: appointment_session_member_bool_exp!
    $set: appointment_session_member_set_input!
  ) {
    update_appointment_session_member(where: $condition, _set: $set) {
      affected_rows
    }
  }
`

export const INSERT_APPOINTMENT_SESSION = gql`
  mutation INSERT_APPOINTMENT_SESSION($object: appointment_session_insert_input!) {
    insert_appointment_session_one(object: $object) {
      id
    }
  }
`
