import { gql, useQuery } from '@apollo/client'
import { APP_GET_APPOINTMENT, APP_GET_APPOINTMENTS } from '@havppen/gql/src/appointment'
import { MEMBER_PUBLIC_COLUMNS } from '@havppen/gql/src/member'
import types from '@havppen/gql/types'
import { AppointmentMetadataProps, AppointmentSessionType } from '@havppen/types/src/appointment'
import { mapMemberPublicFromGql } from '@havppen/types/src/member'
import {
  ProductDefaultProps,
  ProductDiscountProps,
  ProductStatus,
  ProductStyleMetadataBriefProps,
} from '@havppen/types/src/product'
import { useMemo } from 'react'
import { useApp } from 'src/contexts/AppContext'
import { useAuth } from 'src/contexts/AuthContext'
import { MemberPublicBriefProps } from 'src/types/member'
import { mapProductDiscountFromGql } from 'src/types/product'
import { getDiscount } from 'src/utils/discount'
import { NIL as NIL_UUID, validate as isUUID } from 'uuid'
import {
  AppointmentsQueryOptions,
  formatGqlAppointments,
  mapAppointmentsQueryOptionsToGqlVariables,
} from './appointment'

export type AppointmentBriefProps = ProductDefaultProps &
  ProductStyleMetadataBriefProps & {
    title: string
    salePrice: number | null
    status: ProductStatus

    isPrivate: boolean
    isLive: boolean

    featuredImageUrl: string | null
    coverVideoUrl: string | null
    authorId: string | null
    author: MemberPublicBriefProps | null

    defaultLocation: string | null
    duration: number
    participantLowerLimit: number
    defaultParticipantLimit: number | null

    createdAt: Date
    updatedAt: Date
  }

export type AppointmentSessionBriefProps = {
  id: string
  startAt: Date
  endAt: Date
  isExpired: boolean
}
export type AppointmentSessionUpcomingProps = AppointmentSessionBriefProps & {
  externalMeetingUrl: string | null
}
export type AppointmentSessionProps = AppointmentSessionBriefProps & {
  currentReservedCount: number
}

export type AppointmentSessionReserveStatus = 'LOCKED' | 'RESERVED'
export type AppointmentSessionStatusProps = {
  appointmentId: string
  startAt: Date
  reservedCount: number
  reservedStatus: AppointmentSessionReserveStatus
}

export type AppointmentSessionDetailProps = AppointmentSessionProps & {
  no?: number
  appointmentId: string
  appointmentTitle: string
  authorId: string | null
  author: MemberPublicBriefProps | null
  currentReservedCount: number
  externalMeetingUrl: string | null
  revokedAt: Date | null
}

export type AppointmentScheduleProps = {
  id: string
  title: string
  featuredImageUrl: string | null

  defaultLocation: string | null
  purchaseCount: number
  duration: number
  participantLowerLimit: number
  defaultParticipantLimit: number | null
  metadata: AppointmentMetadataProps
}

export type AppointmentProps = AppointmentBriefProps & {
  abstract: string | null
  content: string | null
  metadata: AppointmentMetadataProps
  seoAttributes?: {
    [key: string]: any
  }
  type: AppointmentSessionType | undefined
  appointmentDiscounts: ProductDiscountProps[]
}

export type AppointmentPurchasedProps = AppointmentBriefProps & {
  abstract: string | null
  metadata: AppointmentMetadataProps
  type: AppointmentSessionType | undefined
  currentReservedCount: number
  availableReservationCount: number | null
  appointmentSessions: AppointmentSessionDetailProps[]
}

export const getAppointmentSessionExternalMeetingUrl = (options?: {
  type: AppointmentSessionType | undefined
  appointmentSessionId: string
  appointmentSessionMemberId?: string
}) => {
  const { type, appointmentSessionId, appointmentSessionMemberId } = options || {}

  if (appointmentSessionMemberId) {
    return `/appointment/join-session/${appointmentSessionMemberId}`
  }

  switch (type) {
    case 'jitsi':
      return `https://meet.jit.si/${appointmentSessionId}`
    default:
      return null
  }
}

export const useAppointments = (options?: AppointmentsQueryOptions) => {
  const { id: appId } = useApp()
  const { isAuthenticated, currentMemberId } = useAuth()

  const variables = mapAppointmentsQueryOptionsToGqlVariables(
    {
      isAuthenticated,
      currentMemberId,
      ...options,
    },
    { appId },
  )
  const { loading, error, data, refetch } = useQuery<types.APP_GET_APPOINTMENTS, types.APP_GET_APPOINTMENTSVariables>(
    APP_GET_APPOINTMENTS,
    { variables },
  )

  const appointments: AppointmentBriefProps[] = useMemo(() => {
    return formatGqlAppointments(data)
  }, [data])

  return {
    appointmentCount: data?.appointment_aggregate.aggregate?.count || 0,
    appointments,
    loadingAppointments: loading,
    errorAppointments: error,
    refetchAppointments: refetch,
  }
}

export const useAppointment = (appointmentSlug: string) => {
  const { defaultAvatarUrl } = useApp()
  const isAppointmentId = useMemo(() => isUUID(appointmentSlug), [appointmentSlug])
  const { loading, error, data, refetch } = useQuery<types.APP_GET_APPOINTMENT, types.APP_GET_APPOINTMENTVariables>(
    APP_GET_APPOINTMENT,
    {
      variables: {
        condition: {
          id: isAppointmentId ? { _eq: appointmentSlug } : undefined,
          slug: isAppointmentId ? undefined : { _eq: appointmentSlug },
        },
      },
    },
  )

  const appointment: AppointmentProps | null = useMemo(() => {
    return (
      (data?.appointment ?? []).map(appointment => {
        const participantLowerLimit = appointment.participant_lower_limit ?? 0
        const defaultParticipantLimit = appointment.default_participant_limit ?? null
        const listPrice = appointment.list_price
        const status = appointment.status as ProductStatus

        const productDiscounts = mapProductDiscountFromGql(appointment.appointment_discounts)
        const { discountPrice: salePrice } = getDiscount({
          productDiscounts,
          listPrice,
          status,
        })

        return {
          id: appointment.id,
          slug: appointment.slug,
          title: appointment.title,
          categories: appointment.appointment_categories.map(appointmentCategory => ({
            id: appointmentCategory.category.id,
            slug: appointmentCategory.category.slug,
            name: appointmentCategory.category.name,
          })),
          listPrice,
          salePrice,

          isPrivate: appointment.is_private ?? false,
          isLive: appointment.is_live ?? false,

          isPriceHidden: appointment.metadata?.isPriceHidden ?? false,
          isAuthorInfoHidden: appointment.metadata?.isAuthorInfoHidden ?? false,
          isParticipantCountHidden: appointment.metadata?.isParticipantCountHidden ?? false,
          isDiscountCountdownVisible: appointment.metadata?.isDiscountCountdownVisible ?? false,

          abstract: appointment.abstract,
          content: appointment.content,
          metadata: appointment.metadata,
          seoAttributes: appointment.seo_attributes,
          type: appointment.type,
          path: `/appointment/${appointment.slug ?? appointment.id}`,
          coverVideoUrl: appointment.cover_video_url,
          featuredImageUrl: appointment.featured_image_url,
          duration: appointment.duration,
          authorId: appointment.author_id,
          author: appointment.author ? mapMemberPublicFromGql(appointment.author, { defaultAvatarUrl }) : null,

          defaultLocation: appointment.default_location,
          participantLowerLimit,
          defaultParticipantLimit,
          purchaseCount: appointment.appointment_possessions_aggregate.aggregate?.count ?? 0,

          reviewCount: appointment.product_review_summary?.review_count ?? null,
          averageRating: appointment.product_review_summary?.average_rating ?? null,

          status: appointment.status as ProductStatus,
          createdAt: new Date(appointment.created_at),
          updatedAt: new Date(appointment.updated_at),

          appointmentDiscounts: productDiscounts,
        }
      })[0] ?? null
    )
  }, [data, defaultAvatarUrl])

  return {
    appointment,
    loadingAppointment: loading,
    errorAppointment: error,
    refetchAppointment: refetch,
  }
}

export const useAppointmentSessionStatuses = (appointmentId: string) => {
  const { loading, error, data, refetch } = useQuery<
    types.GET_APPOINTMENT_SESSION_STATUSES,
    types.GET_APPOINTMENT_SESSION_STATUSESVariables
  >(
    gql`
      query GET_APPOINTMENT_SESSION_STATUSES($condition: appointment_session_status_bool_exp!) {
        appointment_session_status(where: $condition) {
          appointment_id
          reserve_session_time
          reserve_count
          reserve_status
        }
      }
    `,
    {
      variables: {
        condition: {
          appointment_id: { _eq: appointmentId },
          reserve_session_time: { _gte: 'now()' },
        },
      },
    },
  )

  const appointmentSessionStatuses: AppointmentSessionStatusProps[] = useMemo(() => {
    return (
      data?.appointment_session_status.map(appointment_session_status => {
        return {
          appointmentId: appointment_session_status.appointment_id,
          startAt: new Date(appointment_session_status.reserve_session_time),
          reservedCount: appointment_session_status.reserve_count ?? 0,
          reservedStatus: appointment_session_status.reserve_status as AppointmentSessionReserveStatus,
        }
      }) || []
    )
  }, [data])

  return {
    appointmentSessionStatuses,
    loadingAppointmentSessionStatuses: loading,
    errorAppointmentSessionStatuses: error,
    refetchAppointmentSessionStatuses: refetch,
  }
}

export const useAppointmentsPurchased = (options?: { search?: string }) => {
  const { currentMemberId } = useAuth()
  const { logoUrl, defaultAvatarUrl } = useApp()

  const { loading, error, data, refetch } = useQuery<
    types.GET_APPOINTMENTS_PURCHASED,
    types.GET_APPOINTMENTS_PURCHASEDVariables
  >(GET_APPOINTMENTS_PURCHASED, {
    variables: {
      currentMemberId,
      condition: {
        appointment_possessions: { member_id: { _eq: currentMemberId } },
        title: options?.search ? { _like: `%${options.search}%` } : undefined,
      },
    },
  })

  const appointmentsPurchased: AppointmentPurchasedProps[] = useMemo(() => {
    return loading || error || !data
      ? []
      : data.appointment.map(appointment => {
          const now = new Date()
          const purchaseCount = appointment.appointment_possessions_aggregate.aggregate?.sum?.quantity || 0

          const authorId = appointment.author_id
          const author = mapMemberPublicFromGql(appointment.author, { defaultAvatarUrl })
          const type = appointment.type
          const participantLowerLimit = appointment.participant_lower_limit ?? 0
          const defaultParticipantLimit = appointment.default_participant_limit ?? null
          const currentReservedCount = appointment.appointment_sessions.reduce(
            (sum, appointment_session) => sum + appointment_session.reserved_appointment_session_members.length,
            0,
          )

          return {
            id: appointment.id,
            slug: appointment.slug,
            title: appointment.title,
            categories: [],
            path: `/appointment/${appointment.slug ?? appointment.id}`,
            abstract: appointment.abstract,
            listPrice: appointment.list_price,
            salePrice: null,
            coverVideoUrl: appointment.cover_video_url,
            featuredImageUrl: appointment.featured_image_url || logoUrl || '',
            metadata: appointment.metadata,
            type,

            isPrivate: appointment.is_private ?? false,
            isLive: appointment.is_live ?? false,
            isPriceHidden: appointment.is_price_hidden ?? false,

            defaultLocation: appointment.default_location,
            duration: appointment.duration,
            authorId,
            author,

            participantLowerLimit,
            defaultParticipantLimit,
            status: appointment.status as ProductStatus,

            createdAt: new Date(appointment.created_at),
            updatedAt: new Date(appointment.updated_at),

            purchaseCount,
            currentReservedCount,
            availableReservationCount: purchaseCount - currentReservedCount,

            reviewCount: appointment.product_review_summary?.review_count ?? null,
            averageRating: appointment.product_review_summary?.average_rating ?? null,

            appointmentSessions: appointment.appointment_sessions.map(appointment_session => {
              const endAt = new Date(appointment_session.end_at)
              const currentReservedCount =
                appointment_session.appointment_session_members_aggregate.aggregate?.count ?? 0
              const appointmentSessionMemberId: string | undefined =
                appointment_session.reserved_appointment_session_members[0]?.id

              const sessionNo = appointment_session.reserved_appointment_session_members[0]?.no
              const revokedAt = appointment_session.reserved_appointment_session_members[0]?.revoked_at
                ? new Date(appointment_session.reserved_appointment_session_members[0]?.revoked_at)
                : null

              return {
                id: appointment_session.id,
                no: sessionNo,
                startAt: new Date(appointment_session.start_at),
                endAt,
                isExpired: now > endAt,
                revokedAt,
                currentReservedCount,
                authorId,
                author,
                appointmentTitle: appointment.title,
                appointmentId: appointment.id,
                externalMeetingUrl: getAppointmentSessionExternalMeetingUrl({
                  type,
                  appointmentSessionId: appointment_session.id,
                  appointmentSessionMemberId,
                }),
              }
            }),
          }
        })
  }, [data, defaultAvatarUrl, error, loading, logoUrl])

  return {
    loadingAppointmentsPurchased: loading,
    appointmentsPurchased,
    errorAppointmentsPurchased: error,
    refetchAppointmentsPurchased: refetch,
  }
}

export const useAppointmentsOwned = (options?: { search?: string | null }) => {
  const { currentMemberId } = useAuth()
  const { logoUrl, defaultAvatarUrl } = useApp()

  const { loading, error, data, refetch } = useQuery<
    types.GET_APPOINTMENTS_OWNED,
    types.GET_APPOINTMENTS_OWNEDVariables
  >(GET_APPOINTMENTS_OWNED, {
    variables: {
      condition: {
        author_id: { _eq: currentMemberId },
        title: options?.search ? { _like: `%${options.search}%` } : undefined,
      },
    },
  })

  const appointmentsOwned: AppointmentPurchasedProps[] = useMemo(() => {
    return (data?.appointment || []).map(appointment => {
      const now = new Date()

      const authorId = appointment.author_id
      const author = mapMemberPublicFromGql(appointment.author, { defaultAvatarUrl })
      const type = appointment.type
      const participantLowerLimit = appointment.participant_lower_limit ?? 0
      const defaultParticipantLimit = appointment.default_participant_limit ?? null

      return {
        id: appointment.id,
        slug: appointment.slug,
        title: appointment.title,
        categories: [],
        path: `/appointment/${appointment.slug ?? appointment.id}`,
        abstract: appointment.abstract,
        listPrice: appointment.list_price,
        salePrice: null,
        coverVideoUrl: appointment.cover_video_url,
        featuredImageUrl: appointment.featured_image_url || logoUrl || '',
        metadata: appointment.metadata,
        type,

        isPrivate: appointment.is_private ?? false,
        isLive: appointment.is_live ?? false,
        isPriceHidden: appointment.is_price_hidden ?? false,

        defaultLocation: appointment.default_location,
        duration: appointment.duration,
        authorId,
        author,

        participantLowerLimit,
        defaultParticipantLimit,
        status: appointment.status as ProductStatus,

        createdAt: new Date(appointment.created_at),
        updatedAt: new Date(appointment.updated_at),

        purchaseCount: 0,
        currentReservedCount: 0,
        availableReservationCount: 0,

        reviewCount: appointment.product_review_summary?.review_count ?? null,
        averageRating: appointment.product_review_summary?.average_rating ?? null,

        appointmentSessions: appointment.appointment_sessions.map(appointment_session => {
          const endAt = new Date(appointment_session.end_at)
          const currentReservedCount = appointment_session.appointment_session_members_aggregate.aggregate?.count ?? 0

          return {
            id: appointment_session.id,
            startAt: new Date(appointment_session.start_at),
            endAt,
            isExpired: now > endAt,
            currentReservedCount,
            authorId,
            author,
            appointmentTitle: appointment.title,
            appointmentId: appointment.id,
            revokedAt: null,
            externalMeetingUrl: getAppointmentSessionExternalMeetingUrl({
              type,
              appointmentSessionId: appointment_session.id,
            }),
          }
        }),
      }
    })
  }, [data, defaultAvatarUrl, logoUrl])

  const appointmentsOwnedCount = data?.appointment_aggregate.aggregate?.count ?? 0

  return {
    appointmentsOwnedCount,
    loadingAppointmentsOwned: loading,
    appointmentsOwned,
    errorAppointmentsOwned: error,
    refetchAppointmentsOwned: refetch,
  }
}

export const useAppointmentUpcomingReservedSession = (appointmentIdOrSlug: string) => {
  const { currentMemberId } = useAuth()
  const isAppointmentId = useMemo(() => isUUID(appointmentIdOrSlug), [appointmentIdOrSlug])

  const { loading, error, data, refetch } = useQuery<
    types.GET_APPOINTMENT_UPCOMING_RESERVED_SESSION,
    types.GET_APPOINTMENT_UPCOMING_RESERVED_SESSIONVariables
  >(
    gql`
      query GET_APPOINTMENT_UPCOMING_RESERVED_SESSION($appointmentCondition: appointment_bool_exp, $memberId: String!) {
        appointment_session(
          where: {
            _or: [
              { appointment_session_members: { member_id: { _eq: $memberId } } }
              { appointment: { author_id: { _eq: $memberId } } }
            ]
            end_at: { _gte: "now()" }
            appointment: $appointmentCondition
          }
          order_by: { start_at: asc_nulls_last }
          limit: 1
        ) {
          id
          start_at
          end_at

          appointment_session_members(where: { member_id: { _eq: $memberId } }, limit: 1) {
            id
          }
        }

        appointment(where: $appointmentCondition, limit: 1) {
          id
          type: metadata(path: "type")
        }
      }
    `,
    {
      skip: currentMemberId === NIL_UUID,
      variables: {
        memberId: currentMemberId,
        appointmentCondition: {
          id: isAppointmentId ? { _eq: appointmentIdOrSlug } : undefined,
          slug: isAppointmentId ? undefined : { _eq: appointmentIdOrSlug },
        },
      },
    },
  )

  const appointmentUpcomingSession: AppointmentSessionUpcomingProps | null = useMemo(() => {
    const now = new Date()
    const appointment = data?.appointment[0]

    return loading || error || !data
      ? null
      : data.appointment_session.map(appointment_session => ({
          id: appointment_session.id,
          startAt: new Date(appointment_session.start_at),
          endAt: new Date(appointment_session.end_at),
          isExpired: now > new Date(appointment_session.end_at),
          externalMeetingUrl: getAppointmentSessionExternalMeetingUrl({
            type: appointment?.type,
            appointmentSessionId: appointment_session.id,
            appointmentSessionMemberId: appointment_session.appointment_session_members[0]?.id,
          }),
        }))[0]
  }, [data, error, loading])

  return {
    appointmentUpcomingSession,
    loadingAppointmentUpcomingSession: loading,
    errorAppointmentUpcomingSession: error,
    refetchAppointmentUpcomingSession: refetch,
  }
}

export const GET_APPOINTMENTS_PURCHASED = gql`
  query GET_APPOINTMENTS_PURCHASED($condition: appointment_bool_exp!, $currentMemberId: String!) {
    appointment_aggregate(where: $condition) {
      aggregate {
        count
      }
    }
    appointment(where: $condition) {
      id
      slug
      title
      abstract
      list_price
      cover_video_url
      featured_image_url
      status
      metadata

      type: metadata(path: "type")
      is_price_hidden: metadata(path: "isPriceHidden")

      default_location
      participant_lower_limit
      default_participant_limit
      duration

      is_private
      is_live

      author_id
      author {
        ...MEMBER_PUBLIC_COLUMNS
      }

      product_review_summary {
        review_count
        average_rating
      }

      appointment_possessions_aggregate(where: { member_id: { _eq: $currentMemberId } }) {
        aggregate {
          sum {
            quantity
          }
        }
      }
      appointment_sessions(
        where: { appointment_session_members: { member_id: { _eq: $currentMemberId } } }
        order_by: { start_at: desc_nulls_last }
      ) {
        id
        start_at
        end_at
        appointment_session_members_aggregate {
          aggregate {
            count
          }
        }
        reserved_appointment_session_members: appointment_session_members(
          where: { member_id: { _eq: $currentMemberId } }
        ) {
          id
          no
          revoked_at
        }
      }

      created_at
      updated_at
    }
  }
  ${MEMBER_PUBLIC_COLUMNS}
`

export const GET_APPOINTMENTS_OWNED = gql`
  query GET_APPOINTMENTS_OWNED($condition: appointment_bool_exp!) {
    appointment_aggregate(where: $condition) {
      aggregate {
        count
      }
    }
    appointment(where: $condition) {
      id
      slug
      title
      abstract
      list_price
      cover_video_url
      featured_image_url
      status
      metadata

      type: metadata(path: "type")
      is_price_hidden: metadata(path: "isPriceHidden")

      default_location
      participant_lower_limit
      default_participant_limit
      duration

      is_private
      is_live

      author_id
      author {
        ...MEMBER_PUBLIC_COLUMNS
      }

      product_review_summary {
        review_count
        average_rating
      }

      appointment_sessions(
        where: { appointment_session_members_aggregate: { count: { predicate: { _gt: 0 } } } }
        order_by: { start_at: desc_nulls_last }
      ) {
        id
        start_at
        end_at
        appointment_session_members_aggregate {
          aggregate {
            count
          }
        }
      }

      created_at
      updated_at
    }
  }
  ${MEMBER_PUBLIC_COLUMNS}
`
